import { isWechatMiniProgram, getGoodieBagCampaignSlot } from './utils'

const _sendGoogleEvent = function (eventType, trackInfo) {
  window.gtag('event', eventType, trackInfo)
}

// const _sendAdobeEvent = function (eventType, trackInfo, route) {
//   let eventName
//   delete trackInfo['__couponId']
//   console.log('@63, send event: adobe', eventType, trackInfo)
//   if (!window.digitalData) {
//     window.digitalData = {
//       pageInstanceID: 'HKTB e-solution',
//       page: {
//         pageInfo: {},
//         link: {}
//       }
//     }
//   }

//   if (isWechatMiniProgram() && !window.digitalData['platform']) {
//     console.log('@21, add aaid')
//     window.digitalData['platform'] = 'wechat-miniprogram'

//     if (route.query.aaid) { window.digitalData['aaid'] = route.query.aaid }
//   }

//   if (eventType === 'onclick') {
//     window.digitalData.page.link = trackInfo
//     // keep previous page info
//     // window.digitalData.page.pageInfo = null
//     eventName = 'Track Data Analytics ID Click'
//     console.log('@63xb, click event', eventName, window.digitalData)
//   } else { // page view
//     window.digitalData.page.link = null
//     window.digitalData.page.pageInfo = {
//       pageID: '1',
//       pagePath: window.location.pathname,
//       destinationURL: window.location.href,
//       referringURL: window.document.referrer,
//       market: 'Others',
//       subSection: 'n/a',
//       subSection2: 'n/a'
//     }

//     Object.assign(window.digitalData.page.pageInfo, trackInfo)

//     eventName = 'Track Page View'

//     console.log('@63xc, page view event', eventName, window.digitalData)
//   }

//   const customEvent = new Event(eventName)
//   window.dispatchEvent(customEvent)
// }

const sendAdobeEvent = function (store, eventType, trackInfo, elapsedTime, route) {
  if (!window.digitalData) {
    window.digitalData = {
      pageInstanceID: 'HKTB e-solution',
      page: {
        pageInfo: {},
        link: {}
      }
    }
  }

  if (isWechatMiniProgram() && !window.digitalData['platform']) {
    console.log('@21, add aaid')
    window.digitalData['platform'] = 'wechat-miniprogram'

    if (route.query.aaid) { window.digitalData['aaid'] = route.query.aaid }
  }
  return
  // // did not set user agent here

  // // very similar to sendWoopraAndGoogleEvent, but all key names are different
  // let needRetry = false
  // if (trackInfo['__couponId']) {
  //   const couponId = trackInfo['__couponId']
  //   const coupon = store.getters['coupons/couponDetail'](couponId)
  //   if (coupon) {
  //     const campaign = coupon.campaign ? coupon.campaign : {}
  //     trackInfo['campaignId'] = trackInfo['campaignId'] || campaign.id
  //     trackInfo['campaignName'] = trackInfo['campaignName'] || campaign.name
  //     trackInfo['campaignType'] = trackInfo['campaignType'] || campaign.type
  //     trackInfo['campaignCategory'] = trackInfo['campaignCategory'] || getCampaignCategory(campaign)
  //     trackInfo['couponId'] = trackInfo['__couponId']
  //     if (campaign.is_starred) {
  //       trackInfo['isStarred'] = 1
  //     }

  //     if (coupon.template) {
  //       trackInfo['couponSetId'] = coupon.template.id
  //       trackInfo['couponSetName'] = coupon.template.name
  //       trackInfo['redemptionMethod'] = coupon.template.redemption_method
  //     }
  //   } else {
  //     needRetry = true
  //   }
  // } else if (trackInfo['campaignId'] && !trackInfo['campaignType']) {
  //   const campaignId = trackInfo['campaignId']
  //   const campaign = store.getters['campaigns/campaignDetail'](campaignId)

  //   if (campaign) {
  //     trackInfo['campaignName'] = trackInfo['campaignName'] || campaign.name
  //     trackInfo['campaignType'] = trackInfo['campaignType'] || campaign.type
  //     trackInfo['campaignCategory'] = trackInfo['campaignCategory'] || getCampaignCategory(campaign)
  //     if (campaign.is_starred) {
  //       trackInfo['isStarred'] = 1
  //     }
  //   } else {
  //     needRetry = true
  //   }
  // }
  // if (trackInfo['goodiebagID'] && !trackInfo['goodiebagName']) {
  //   console.log('@98@sendevent, campaign info lost', JSON.stringify(trackInfo))

  //   const goodiebagId = trackInfo['goodiebagID']
  //   const goodieBagDetail = store.getters['goodiebags/goodieBagDetail'](goodiebagId)
  //   if (goodieBagDetail) {
  //     let rewardType = goodieBagDetail.reward_type
  //     const goodiebagName = goodieBagDetail.name
  //     if (rewardType === 'BOTH') {
  //       rewardType = 'cash, offer'
  //     } else if (rewardType === 'GIFT') {
  //       rewardType = 'offer'
  //     } else if (rewardType === 'CASH_VOUCHER') {
  //       rewardType = 'cash'
  //     } else if (rewardType === 'FORTUNE_BAG') {
  //       rewardType = 'Goodie bag offer (Direct assign)'
  //     }
  //     trackInfo['goodiebagRewardType'] = rewardType
  //     trackInfo['goodiebagName'] = goodiebagName
  //   } else {
  //     needRetry = true
  //   }
  // }
  // if (needRetry) {
  //   if (!elapsedTime) {
  //     elapsedTime = 0
  //   }

  //   if (elapsedTime < 1500) {
  //     setTimeout(function () {
  //       sendAdobeEvent(store, eventType, trackInfo, elapsedTime + 300, route)
  //     }, 300)
  //     return
  //   }
  // }
  // _sendAdobeEvent(eventType, trackInfo, route)
}

const getVistiorID = function (store) {
  const visitorId = store.getters['auth/trackingSsoUid'] || 'n/a'

  return visitorId
}

const getMembershipId = function (store) {
  const membershipId = store.getters['auth/trackingMembershipId'] || 'n/a'

  return membershipId
}

const getCustomerCountryCode = function (store) {
  let countryCode = store.getters['auth/trackingCountryCode'] || ''
  if (!countryCode) {
    countryCode = localStorage.getItem('countryCode') || ''
  }
  return countryCode
}

const _sendWoopraEvent = function (eventType, trackInfo) {
  delete trackInfo['__coupon_id']
  console.log('@63d, send event: woopra', eventType, trackInfo)

  window.woopra.track(eventType, trackInfo)
}

const sendWoopraAndGoogleEvent = function (store, eventType, trackInfo, elapsedTime) {
  const userAgent = navigator.userAgent
  trackInfo['userAgent'] = userAgent
  let needRetry = false

  if (trackInfo['__coupon_id']) {
    const couponId = trackInfo['__coupon_id']
    const coupon = store.getters['coupons/couponDetail'](couponId)
    if (coupon) {
      const campaign = coupon.campaign || {}
      trackInfo['campaign_id'] = trackInfo['campaign_id'] || campaign.id
      trackInfo['campaign_name'] = trackInfo['campaign_name'] || campaign.name
      trackInfo['campaign_type'] = trackInfo['campaign_type'] || campaign.type
      trackInfo['campaign_category'] = trackInfo['campaign_category'] || getCampaignCategory(campaign)
      trackInfo['coupon_id'] = trackInfo['__coupon_id']
      if (campaign.is_starred) {
        trackInfo['is_starred'] = 1
      }
      if (coupon.template) {
        trackInfo['coupon_set_id'] = coupon.template.id
        trackInfo['coupon_set_name'] = coupon.template.name
        trackInfo['redemption_method'] = coupon.template.redemption_method
      }
    } else {
      needRetry = true
    }
  } else if (trackInfo['campaign_id'] && !trackInfo['campaign_type']) {
    console.log('@98@sendevent, campaign info lost', JSON.stringify(trackInfo))

    const campaignId = trackInfo['campaign_id']
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)

    if (campaign) {
      trackInfo['campaign_name'] = trackInfo['campaign_name'] || campaign.name
      trackInfo['campaign_type'] = trackInfo['campaign_type'] || campaign.type
      trackInfo['campaign_category'] = trackInfo['campaign_category'] || getCampaignCategory(campaign)
      if (campaign.is_starred) {
        trackInfo['is_starred'] = 1
      }
    } else {
      needRetry = true
    }
  }
  if (trackInfo['goodiebag_id'] && !trackInfo['goodiebag_name']) {
    console.log('@98@sendevent, goodiebag info lost', JSON.stringify(trackInfo))

    const goodiebagId = trackInfo['goodiebag_id']
    const goodieBagDetail = store.getters['goodiebags/goodieBagDetail'](goodiebagId)
    if (goodieBagDetail) {
      let rewardType = goodieBagDetail.reward_type
      const goodiebagName = goodieBagDetail.name

      if (rewardType === 'BOTH') {
        rewardType = 'cash, offer'
      } else if (rewardType === 'GIFT') {
        rewardType = 'offer'
      } else if (rewardType === 'CASH_VOUCHER') {
        rewardType = 'cash'
      } else if (rewardType === 'FORTUNE_BAG') {
        rewardType = 'Goodie bag offer (Direct assign)'
      }
      trackInfo['goodiebag_reward_type'] = rewardType
      trackInfo['goodiebag_name'] = goodiebagName
    } else {
      needRetry = true
    }
  }
  if (needRetry) {
    if (!elapsedTime) {
      elapsedTime = 0
    }

    if (elapsedTime < 1500) {
      setTimeout(function () {
        sendWoopraAndGoogleEvent(store, eventType, trackInfo, elapsedTime + 300)
      }, 300)
      return
    }
  }
  console.log('@event info ready send', trackInfo)
  _sendWoopraEvent(eventType, trackInfo)
  _sendGoogleEvent(eventType, trackInfo)
}

let gPreviousId = ''
const woopraIdentify = function (uuid, membershipId) {
  var currentId = String(uuid) + ',' + String(membershipId)
  if ((uuid || gPreviousId) && currentId !== gPreviousId) {
    window.woopra.identify({
      id: uuid,
      uuid,
      membershipId
    })

    // window.woopra.track()

    console.log('woopra identify called:', uuid, membershipId, gPreviousId)
  }
  gPreviousId = currentId
}

const getCampaignCategory = function (campaign) {
  // const campaign = store.getters['campaigns/campaignDetail'](campaignId)
  const campaignCategoryList = (campaign && campaign.categories) ? campaign.categories : []

  const category = campaignCategoryList.length ? campaignCategoryList[0].name : null

  console.log('@84@135', campaign, campaignCategoryList, category)

  return category
}

const getTrackingChannel = function () {
  if (isWechatMiniProgram()) {
    return 'esolution-mp'
  }

  return 'esolution-web'
}

const getInfoByGoodieBagRecordId = function (goodieBagRecordId, goodieBagRecordType, store, route) {
  const woopraTrackInfo = {}
  const adobeTrackInfo = {}
  const goodiebagRecordDetail = store.getters['goodiebagparticipationrecords/recordDetail'](goodieBagRecordId)
  console.log('@258', goodieBagRecordId, goodiebagRecordDetail)
  if (goodiebagRecordDetail) {
    console.log('@530', goodiebagRecordDetail)
    const goodiebagID = goodiebagRecordDetail['goodie_bag']['id']
    const goodiebagName = goodiebagRecordDetail['goodie_bag']['name']
    let reward_type = goodiebagRecordDetail['goodie_bag']['reward_type']

    if (reward_type === 'BOTH') {
      reward_type = 'cash, offer'
    } else if (reward_type === 'GIFT') {
      reward_type = 'offer'
    } else if (reward_type === 'CASH_VOUCHER') {
      reward_type = 'cash'
    }

    adobeTrackInfo['goodiebagID'] = goodiebagID
    adobeTrackInfo['goodiebagName'] = goodiebagName

    woopraTrackInfo['goodiebag_id'] = goodiebagID
    woopraTrackInfo['goodiebag_name'] = goodiebagName

    const campaignSlot = route.query.slotId ? getGoodieBagCampaignSlot(goodiebagRecordDetail, route.query.slotId) : null

    if (goodieBagRecordType === 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG') {
      const ownedCredit = campaignSlot ? campaignSlot.owned_reward : goodiebagRecordDetail['owned_credit']
      adobeTrackInfo['goodiebagCash'] = ownedCredit
      woopraTrackInfo['goodiebag_cash'] = ownedCredit
    } else if (goodieBagRecordType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
      const ownedQuota = campaignSlot ? campaignSlot.owned_reward : goodiebagRecordDetail['owned_quota']
      adobeTrackInfo['goodiebagCash'] = ownedQuota
      woopraTrackInfo['goodiebag_cash'] = ownedQuota
    }
    adobeTrackInfo['goodiebagRewardType'] = reward_type
    woopraTrackInfo['goodiebag_reward_type'] = reward_type
  }
  return { 'woopraTrackInfo': woopraTrackInfo, 'adobeTrackInfo': adobeTrackInfo }
}

const _sendPageViewAnalytics = function (route, locale, store, extra = {}) {
  // send out adobe tracking
  let adobeTrackInfo = {}
  let woopraTrackInfo = {}

  const { name } = route
  const popupType = extra['popupType']
  const pageName = extra['pageName']

  let needTracking = true
  let campaignCategory
  let goodieBagRecordId = null
  let goodieBagRecordType = null
  let goodiebagID = null
  let titleCategory = ''
  console.log('@63', name)
  // https://docs.google.com/spreadsheets/d/1E_dNBYHS4ObfJfOi3Ymu_p8yrufoQL7yUFCJCFV_Zsg/edit#gid=888638322
  if (name === 'home') { // spreadsheet row 4
    adobeTrackInfo['pageName'] = 'home'

    woopraTrackInfo['title'] = 'home'

    titleCategory = 'home'

    if (route.query.source_channel) {
      woopraTrackInfo['source_channel'] = route.query.source_channel
    } // assume send from url?
  } else if (name === 'explore' && pageName) {
    if (pageName === 'generic_offer_campaignlist_search_result') {
      adobeTrackInfo['pageName'] = 'generic_offer_campaignlist_search_result'

      woopraTrackInfo['title'] = 'generic_offer_campaign_search_result'
      woopraTrackInfo['search_term'] = extra['search_term']
      woopraTrackInfo['search_result_count'] = extra['search_result_count']

      titleCategory = 'explore'
    }
  } else if (name === 'my-account') {
    adobeTrackInfo['pageName'] = 'account_info'

    woopraTrackInfo['title'] = 'account_info'
    titleCategory = 'myAccount'
  } else if (name === 'campaign-detail' || name === 'coupon-detail') {
    const { campaignId, couponId, recordId } = route.query

    if (recordId && campaignId) {
      const campaign = store.getters['campaigns/campaignDetail'](campaignId)
      const type = campaign ? campaign.type : ''
      goodieBagRecordId = recordId
      goodieBagRecordType = type

      adobeTrackInfo['pageName'] = 'offer_detail_goodie_bag'
      adobeTrackInfo['campaignID'] = campaignId
      adobeTrackInfo['campaignName'] = campaign ? campaign.name : ''
      adobeTrackInfo['campaignType'] = type

      woopraTrackInfo['title'] = 'offer_detail_goodie_bag'
      woopraTrackInfo['campaignID'] = campaignId
      woopraTrackInfo['campaignName'] = campaign ? campaign.name : ''
      woopraTrackInfo['campaignType'] = type

      if (campaign && campaign.attribute_tags) {
        adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
        woopraTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      }

      campaignCategory = getCampaignCategory(campaign)

      titleCategory = 'goodieBagCampaignsDetails'
    } else if (campaignId) { // offer detail page
      const campaign = store.getters['campaigns/campaignDetail'](campaignId)

      adobeTrackInfo['pageName'] = 'offer_detail'

      woopraTrackInfo['campaign_id'] = campaignId
      woopraTrackInfo['campaign_name'] = campaign ? campaign.name : ''

      woopraTrackInfo['title'] = 'offer_detail'

      campaignCategory = getCampaignCategory(campaign)

      titleCategory = 'offerDetails'
    } else if (couponId) { // coupon detail page
      const couponDetail = store.getters['coupons/couponDetail'](couponId)

      // console.log('@127', couponDetail)
      adobeTrackInfo['pageName'] = 'coupon_detail'

      adobeTrackInfo['__couponId'] = couponId

      woopraTrackInfo['title'] = 'coupon_detail'

      woopraTrackInfo['__coupon_id'] = couponId

      if (couponDetail && couponDetail.campaign) {
        campaignCategory = getCampaignCategory(couponDetail.campaign)
        console.log('@135b', campaignCategory)

        if (couponDetail.campaign.is_starred) {
          woopraTrackInfo['is_starred'] = 1
        }
      }

      console.log('@396', couponDetail)
      console.log('@396 type', couponDetail.template && couponDetail.template.display_in)
      if (couponDetail.template && couponDetail.template.display_in === 'TICKET_SECTION') {
        titleCategory = 'ticketDetails'
      } else {
        titleCategory = 'couponDetails'
      }
      console.log('@402-381', titleCategory, store.getters['coupons/couponType'])

      if (couponDetail && couponDetail.goodie_bag && couponDetail.goodie_bag.coupon_label) {
        woopraTrackInfo['campaign_label'] = couponDetail.goodie_bag.coupon_label.english_name
      }

      // coupon detail page use copy code
      if (popupType && popupType === 'coupon_detail_use_coupon_confirmation_popup') {
        adobeTrackInfo['analyticsId'] = 'esolution-button'
        adobeTrackInfo['analyticsText'] = 'use_coupon_confirmation_pop_up'

        woopraTrackInfo['title'] = 'coupon_detail_use_coupon_confirmation_popup'
      }
    }
  } else if (name === 'my-account') {
    adobeTrackInfo['pageName'] = 'account_info'

    woopraTrackInfo['title'] = 'account_info'
    titleCategory = 'myAccount'
  } else if (name === 'my-wallet' || name === 'my-wallet-all' ||
    name === 'my-limit-gift' || name === 'my-voucher' ||
    name === 'my-coupons'
  ) {
    adobeTrackInfo['pageName'] = 'my_wallet'

    woopraTrackInfo['title'] = 'my_wallet'

    titleCategory = 'myWallet'

    if (popupType && popupType === 'acquire_goodie_bag_success_pop_up') {
      const { goodie_bag_id } = route.query
      goodiebagID = goodie_bag_id

      titleCategory = 'goodieBag'

      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = 'acquire_goodie_bag_success'

      woopraTrackInfo['title'] = 'acquire_goodie_bag_success_pop_up'
    }
  } else if (name === 'fortune-campaign') {
    adobeTrackInfo['pageName'] = 'offer_detail_fortune_bag'

    woopraTrackInfo['title'] = 'offer_detail_fortune_bag'

    titleCategory = 'goodieBag'
  } else if (name === 'my-reward') {
    adobeTrackInfo['pageName'] = 'goodie_bag_myrewards'

    woopraTrackInfo['title'] = 'goodie_bag_myrewards'

    titleCategory = 'myGoodieBag'
  } else if (name === 'goodie-bag-campaign-list') {
    const { recordId, type } = route.query
    goodieBagRecordId = recordId
    goodieBagRecordType = type

    adobeTrackInfo['pageName'] = 'goodie_bag_campaignlist'
    adobeTrackInfo['goodiebagRewardType'] = type

    woopraTrackInfo['title'] = 'goodie_bag_campaignlist'
    woopraTrackInfo['goodiebag_reward_type'] = type

    titleCategory = 'goodieBagCampaigns'

    if (pageName === 'goodie_bag_campaignlist_search_result') {
      adobeTrackInfo['pageName'] = 'goodie_bag_campaignlist_search_result'

      woopraTrackInfo['title'] = 'goodie_bag_campaign_search_result'
      woopraTrackInfo['search_term'] = extra['search_term']
      woopraTrackInfo['search_result_count'] = extra['search_result_count']
    }
  } else if (name === 'goodie-bag-campaign-list') {
    const { recordId, type } = route.query
    goodieBagRecordId = recordId

    adobeTrackInfo['pageName'] = 'offer_detail_goodie_bag'
    adobeTrackInfo['goodiebagRewardType'] = type

    woopraTrackInfo['title'] = 'offer_detail_goodie_bag'
    woopraTrackInfo['goodiebag_reward_type'] = type

    titleCategory = 'goodieBagCampaigns'
  } else if (name === 'goodie-bag-detail') {
    const { goodie_bag_id } = route.query
    goodiebagID = goodie_bag_id
    adobeTrackInfo['pageName'] = 'goodie_bag_landing'

    woopraTrackInfo['title'] = 'goodie_bag_landing'

    titleCategory = 'goodieBag'

    if (extra && extra['isAgeRestricted']) {
      titleCategory = 'goodieBag'

      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = 'goodie_bag_restriction_pop_up'

      woopraTrackInfo['title'] = 'goodie_bag_restriction_pop_up'
    } else if (extra && extra['couponOutOfStock']) {
      titleCategory = 'goodieBag'

      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = 'goodie bag (fortune bag) out of stock pop up'

      woopraTrackInfo['title'] = 'goodiebag(fortunebag)_out_of_stock_pop_up'
    }
  } else { // this page does not need tracking
    needTracking = false
  }
  console.log('@event init', woopraTrackInfo)

  if (needTracking) {
    const lang = locale
    // const userAgent = navigator.userAgent
    adobeTrackInfo['analyticsLink'] = window.location.pathname
    adobeTrackInfo['language'] = lang
    adobeTrackInfo['section'] = name
    woopraTrackInfo['language'] = lang
    // woopraTrackInfo['userAgent'] = userAgent

    const trackingChannel = getTrackingChannel()
    adobeTrackInfo['channel'] = trackingChannel
    woopraTrackInfo['source_channel'] = trackingChannel

    const visitorId = getVistiorID(store)
    const membershipId = getMembershipId(store)
    adobeTrackInfo['visitorID'] = visitorId

    if (campaignCategory) {
      woopraTrackInfo['campaign_category'] = campaignCategory
      adobeTrackInfo['campaignCategory'] = campaignCategory
    }

    if (goodieBagRecordId) {
      const info = getInfoByGoodieBagRecordId(goodieBagRecordId, goodieBagRecordType, store, route)
      adobeTrackInfo = Object.assign({}, adobeTrackInfo, info['adobeTrackInfo'])
      woopraTrackInfo = Object.assign({}, woopraTrackInfo, info['woopraTrackInfo'])
    } else if (goodiebagID) {
      adobeTrackInfo['goodiebagID'] = goodiebagID
      woopraTrackInfo['goodiebag_id'] = goodiebagID
    }
    console.log('@170, campaign category', campaignCategory)

    console.log('@449', adobeTrackInfo['language'])

    const pageTitleDict = {
      'home': {
        'en': 'Hong Kong Tourism Board',
        'zh-t': '香港旅遊發展局',
        'zh-s': '香港旅游发展局'
      },
      'explore': {
        'en': 'Deals | Hong Kong Tourism Board',
        'zh-t': '全城優惠 | 香港旅遊發展局',
        'zh-s': '全城优惠 | 香港旅游发展局'
      },
      'offerDetails': {
        'en': 'Offer Details | Hong Kong Tourism Board',
        'zh-t': '獎賞詳情 | 香港旅遊發展局',
        'zh-s': '奖赏详情 | 香港旅游发展局'
      },
      'redeemOffer': {
        'en': 'Redeem Offer | Hong Kong Tourism Board',
        'zh-t': '換領獎賞 | 香港旅遊發展局',
        'zh-s': '换领奖赏 | 香港旅游发展局'
      },
      'myWallet': {
        'en': 'My Wallet | Hong Kong Tourism Board',
        'zh-t': '我的錢包 | 香港旅遊發展局',
        'zh-s': '我的钱包 | 香港旅游发展局'
      },
      'couponDetails': {
        'en': 'Coupon Details | Hong Kong Tourism Board',
        'zh-t': '獎賞詳情 | 香港旅遊發展局',
        'zh-s': '奖赏详情 | 香港旅游发展局'
      },
      'ticketDetails': {
        'en': 'Ticket Details | Hong Kong Tourism Board',
        'zh-t': '門票詳情 | 香港旅遊發展局',
        'zh-s': '门票详情 | 香港旅游发展局'
      },
      'useCoupon': {
        'en': 'Use Coupon | Hong Kong Tourism Board',
        'zh-t': '使用獎賞 | 香港旅遊發展局',
        'zh-s': '奖赏详情 | 香港旅游发展局'
      },
      'goodieBag': {
        'en': 'Rewards | Hong Kong Tourism Board',
        'zh-t': '獎賞 | 香港旅遊發展局',
        'zh-s': '奖赏 | 香港旅游发展局'
      },
      'myGoodieBag': {
        'en': 'My Rewards | Hong Kong Tourism Board',
        'zh-t': '我的獎賞 | 香港旅遊發展局',
        'zh-s': '我的奖赏 | 香港旅游发展局'
      },
      'goodieBagCampaigns': {
        'en': 'Rewards Campaigns | Hong Kong Tourism Board',
        'zh-t': '獎賞活動 | 香港旅遊發展局',
        'zh-s': '奖赏活动 | 香港旅游发展局'
      },
      'goodieBagCampaignsDetails': {
        'en': 'Rewards Campaigns Details | Hong Kong Tourism Board',
        'zh-t': '獎賞活動詳情 | 香港旅遊發展局',
        'zh-s': '奖赏活动详情 | 香港旅游发展局'
      },
      'myAccount': {
        'en': 'My Account | Hong Kong Tourism Board',
        'zh-t': '我的帳戶 | 香港旅遊發展局',
        'zh-s': '我的帐户 | 香港旅游发展局'
      }
    }

    if (!titleCategory) {
      titleCategory = 'home'
    }
    document.title = pageTitleDict[titleCategory][lang]

    const countryCode = getCustomerCountryCode(store)
    woopraTrackInfo['country_code'] = countryCode

    console.log('@event before send', woopraTrackInfo)
    woopraIdentify(visitorId, membershipId)
    sendAdobeEvent(store, 'pv', adobeTrackInfo, null, route)
    sendWoopraAndGoogleEvent(store, 'pv', woopraTrackInfo)
  }
}

const _sendClickAnalytics = function (route, locale, store, event) {
  const clickedElement = window.$(event.target)

  const trackElement = clickedElement.attr('data-aid') ? clickedElement : clickedElement.parents('[data-aid]').first()

  // this click no need to be tracked
  if (!trackElement.length) { return }

  let woopraTrackInfo = {}
  let adobeTrackInfo = {}
  let goodieBagRecordId = null
  let goodieBagRecordType = null
  let goodiebagID = null

  const analyticsId = trackElement.data('aid')

  console.log('@142', clickedElement, trackElement, analyticsId)

  let needTracking = true

  if (analyticsId === 'home_banner_click' ||
    analyticsId === 'goodie_bag_myrewards_banner_click' ||
    analyticsId === 'redemption_successfully_banner') {
    const bannerId = trackElement.data('banner-id')
    adobeTrackInfo['bannerID'] = bannerId
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['banner_id'] = bannerId
    woopraTrackInfo['title'] = analyticsId
    if (analyticsId === 'redemption_successfully_banner') {
      const { campaignId } = route.query

      adobeTrackInfo['campaignID'] = campaignId

      woopraTrackInfo['campaign_id'] = campaignId

      const campaign = store.getters['campaigns/campaignDetail'](campaignId)
      if (campaign) {
        adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
        woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
      }
    }
  } else if (analyticsId === 'home_offer_category_click') {
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['category_id'] = trackElement.data('cat-id')
    woopraTrackInfo['category_name'] = trackElement.data('cat-name')
  } else if (analyticsId === 'wallet_list_coupon_click') {
    const couponId = trackElement.data('cou-id')
    const coupon = store.getters['coupons/couponDetail'](couponId)
    const campaignId = coupon.campaign ? coupon.campaign.id : ''

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId

    // const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    const campaign = coupon.campaign
    const category = getCampaignCategory(campaign)

    console.log('@246', category, campaign)
    adobeTrackInfo['campaignType'] = campaign.type
    adobeTrackInfo['campaignName'] = campaign.name
    adobeTrackInfo['campaignCategory'] = category
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
    adobeTrackInfo['__couponId'] = couponId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_type'] = campaign.type
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    woopraTrackInfo['campaign_category'] = category
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    woopraTrackInfo['__coupon_id'] = couponId
  } else if (
    analyticsId === 'coupon_detail_applicable_stores_more' ||
    analyticsId === 'coupon_detail_use_coupon_click' || analyticsId === 'coupon_detail_prompt_take_survey' ||
    analyticsId === 'coupon_detail_redeemed_take_survey'
  ) {
    const couponId = route.query.couponId
    const coupon = store.getters['coupons/couponDetail'](couponId)

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['__couponId'] = couponId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['__coupon_id'] = couponId

    let campaignId = null
    let campaign = null
    if (coupon.campaign) {
      campaignId = coupon.campaign.id
      campaign = store.getters['campaigns/campaignDetail'](campaignId)
      adobeTrackInfo['campaignID'] = campaignId
      woopraTrackInfo['campaign_id'] = campaignId
    }

    if (campaign && campaign.attribute_tags) {
      adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    }

    if (analyticsId === 'coupon_detail_use_coupon_click') {
      adobeTrackInfo['redemptionMethod'] = coupon.template.redemption_method
      woopraTrackInfo['redemption_method'] = coupon.template.redemption_method
    }
  } else if (
    analyticsId === 'offer_detail_brand_more' || analyticsId === 'offer_detail_applicable_stores_more' ||
    analyticsId === 'offer_detail_share_copy_link' || analyticsId === 'offer_detail_share_twitter' ||
    analyticsId === 'offer_detail_share_facebook' || analyticsId === 'offer_detail_get_coupon' ||
    analyticsId === 'offer_detail_merchant_terms_more' || analyticsId === 'offer_detail_general_terms_more' ||
    analyticsId === 'offer_detail_get_coupon_confirm'
  ) {
    console.log('@123456 @690', analyticsId)
    if (analyticsId === 'offer_detail_brand_more' || analyticsId === 'offer_detail_applicable_stores_more' ||
      analyticsId === 'offer_detail_merchant_terms_more' ||
      analyticsId === 'offer_detail_general_terms_more') {
      needTracking = false
      console.log('@123456 @719', route, route.query.from, analyticsId)
    }
    const { recordId, campaignId } = route.query
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    if (recordId) {
      goodieBagRecordId = recordId
      goodieBagRecordType = campaign.type
    }
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignName'] = campaign.name

    if (category) {
      adobeTrackInfo['campaignCategory'] = category
    }

    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    if (category) {
      woopraTrackInfo['campaign_category'] = category
    }
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
  } else if (analyticsId === 'offer_detail_stores_list_click') {
    const campaignId = route.query.campaignId
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }
    const storeIndex = trackElement.data('index')
    const campaignStore = campaign.stores[storeIndex]

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignName'] = campaign.name
    adobeTrackInfo['storeID'] = campaignStore.id
    adobeTrackInfo['storeName'] = campaignStore.name
    adobeTrackInfo['storeDistrict'] = campaignStore.district.name
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
    if (category) {
      adobeTrackInfo['campaignCategory'] = category
    }

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    woopraTrackInfo['store_id'] = campaignStore.id
    woopraTrackInfo['store_name'] = campaignStore.name
    woopraTrackInfo['store_district'] = campaignStore.district.name
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    if (category) {
      woopraTrackInfo['campaign_category'] = category
    }
  } else if (analyticsId === 'coupon_detail_stores_list_click') {
    const couponId = route.query.couponId
    const coupon = store.getters['coupons/couponDetail'](couponId)
    const campaignId = coupon.campaign.id
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    console.log('@759', route, analyticsId, campaignId)
    let category = ''
    if (campaign) {
      const campaignCategorys = campaign.categories
      if (campaignCategorys.length) {
        category = campaignCategorys[0].name
      }
      adobeTrackInfo['campaignType'] = campaign.type
      adobeTrackInfo['campaignName'] = campaign.name
      adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      woopraTrackInfo['campaign_name'] = campaign.name
      woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    }
    const storeIndex = trackElement.data('index')
    const couponStore = coupon.template.applicable_stores[storeIndex]
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId

    adobeTrackInfo['campaignCategory'] = category
    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['store_id'] = couponStore.id
    woopraTrackInfo['store_name'] = couponStore.name
    woopraTrackInfo['store_district'] = couponStore.district.name

    console.log('@759 123', route, analyticsId)
  } else if (
    analyticsId === 'account_change_password_update' || analyticsId === 'add_to_home_screen_click' ||
    analyticsId === 'account_delete' || analyticsId === 'goodie_bag_myrewards_checkmywallet_click' ||
    analyticsId === 'wallet_cashvouchers' || analyticsId === 'wallet_limitedoffers' || analyticsId === 'wallet_tickets' ||
    analyticsId === 'wallet_coupons' || analyticsId === 'home_login' || analyticsId === 'home_my_wallet' ||
    analyticsId === 'home_my_rewards' || analyticsId === 'home_my_account' ||
    analyticsId === 'home_sorting' || analyticsId === 'goodie_bag_sorting' || analyticsId === 'home_filter' || analyticsId === 'goodie_bag_filter' ||
    analyticsId === 'home_filter_clear' || analyticsId === 'header_my_rewards' || analyticsId === 'home_footer_terms' || analyticsId === 'home_footer_privacy' ||
    analyticsId === 'home_footer_faq' || analyticsId === 'home_footer_hktb'
  ) {
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (
    analyticsId === 'coupon_detail_survey_submit' || analyticsId === 'coupon_detail_use_coupon_confirm'
  ) {
    const couponId = route.query.couponId

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['__coupon_id'] = couponId
  } else if (analyticsId === 'goodie_bag_myrewards_tnc_click' || analyticsId === 'home_myrewards_tnc_click') {
    goodieBagRecordId = trackElement.data('grid')
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (analyticsId === 'banner-click') {
    const routeName = route.name
    let bannerId = ''
    if (routeName === 'my-reward') {
      bannerId = trackElement.data('banner-id')

      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = analyticsId

      woopraTrackInfo['title'] = analyticsId
    } else if (
      routeName === 'campaign-detail' ||
      routeName === 'campaign-detail'
    ) {
      bannerId = trackElement.data('banner-id')
      const campaignId = route.query.campaignId
      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = 'redemption_successfully_banner'
      adobeTrackInfo['campaignID'] = campaignId

      woopraTrackInfo['title'] = 'redemption_successfully_banner'
      woopraTrackInfo['campaign_id'] = campaignId

      const campaign = store.getters['campaigns/campaignDetail'](campaignId)
      if (campaign) {
        adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
        woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
      }
    }
    adobeTrackInfo['bannerID'] = bannerId

    woopraTrackInfo['banner_id'] = bannerId
  } else if (analyticsId === 'goodie_bag_myrewards_tnc_click') {
    goodieBagRecordId = trackElement.data('grid')
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (analyticsId === 'goodie_bag_campaignlist_info_click') {
    const { recordId, type } = route.query
    goodieBagRecordId = recordId
    goodieBagRecordType = type
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (
    analyticsId === 'goodie_bag_campaignlist_filter_click' ||
    analyticsId === 'goodie_bag_campaignlist_sorting_click' ||
    analyticsId === 'home_filter_apply' ||
    analyticsId === 'home_sorting_{selection}' ||
    analyticsId === 'home_filter_{selection}'
  ) {
    const routeName = route.name
    if (analyticsId === 'home_filter_{selection}') {
      needTracking = false
      /*
      const value = trackElement.data('akey')
      let filterType = trackElement.data('atype')
      if (filterType === 'offerType') {
        filterType = 'offer_type_label'
      }
      let title = ''
      if (trackElement.find('.category-tick').length &&
        trackElement.find('.category-tick').attr('src').includes('unticked')) {
        title = 'home_filter_diselection_' + value
      } else if (trackElement.find('.category-tick').length &&
        trackElement.find('.category-tick').attr('src').includes('ticked')) {
        title = 'home_filter_' + value
      } else if (trackElement[0] && trackElement[0].childElementCount === 2) {
        title = 'home_filter_' + value
      } else {
        title = 'home_filter_diselection_' + value
      }
      let brand = ''
      if (filterType === 'offer_type_label') {
        brand = window.$('.filter-label:contains("Offer type label")').parent().find('.filter-selected').text()
      } else if (filterType === 'brand') {
        brand = window.$('.filter-label:contains("Brand")').parent().find('.filter-selected').text()
      } else {
        brand = window.$('.filter-label:contains("Category")').parent().find('.filter-selected').text()
      }
      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = title
      adobeTrackInfo[filterType] = brand

      woopraTrackInfo['title'] = title
      woopraTrackInfo[filterType] = brand
      */
    } else if (analyticsId === 'home_sorting_{selection}') {
      const value = trackElement.data('akey')
      const title = 'home_sorting_' + value
      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = title

      woopraTrackInfo['title'] = title
    } else if (routeName === 'goodie-bag-campaign-list' || routeName === 'home') {
      const { recordId, type } = route.query
      goodieBagRecordId = recordId
      goodieBagRecordType = type
      const value = trackElement.data('akey')
      if (analyticsId === 'goodie_bag_campaignlist_sorting_click') {
        adobeTrackInfo['sortingValue'] = value

        woopraTrackInfo['sorting_value'] = value
      } else if (analyticsId === 'goodie_bag_campaignlist_filter_click') {
        adobeTrackInfo['filterValue'] = JSON.stringify(value)

        woopraTrackInfo['filter_value'] = JSON.stringify(value)
      }
      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = analyticsId

      woopraTrackInfo['title'] = analyticsId
    } else {
      const value = trackElement.data('akey')
      adobeTrackInfo['analyticsId'] = 'esolution-button'
      adobeTrackInfo['analyticsText'] = analyticsId

      woopraTrackInfo['title'] = analyticsId
      adobeTrackInfo['filterValue'] = JSON.stringify(value)
      woopraTrackInfo['filter_value'] = JSON.stringify(value)
    }
  } else if (analyticsId === 'offer_detail_image_view') {
    console.log('@560', 'offer_detail_image_view')
    console.log('@123456 @990', route, analyticsId)
    needTracking = false
    /*
    const { recordId, campaignId } = route.query
    goodieBagRecordId = recordId
    adobeTrackInfo['analyticsId'] = 'esolution-event'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId

    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    if (campaign) {
      adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    }
    */
  } else if (analyticsId === 'goodie_bag_campaign_click') {
    needTracking = false
    /*
    const { recordId, campaignId } = route.query
    goodieBagRecordId = recordId
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    if (campaign) {
      goodieBagRecordType = campaign.type
      adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    }
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    */
  } else if (analyticsId === 'acquire_goodie_bag_click') {
    const { goodie_bag_id } = route.query
    goodiebagID = goodie_bag_id
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else {
    needTracking = false
  }

  if (needTracking) {
    const lang = locale
    // const userAgent = navigator.userAgent
    woopraTrackInfo['language'] = lang
    // woopraTrackInfo['userAgent'] = userAgent

    const trackingChannel = getTrackingChannel()
    adobeTrackInfo['channel'] = trackingChannel
    woopraTrackInfo['source_channel'] = trackingChannel

    const visitorId = getVistiorID(store)
    const membershipId = getMembershipId(store)

    adobeTrackInfo['visitorID'] = visitorId
    // woopraTrackInfo['uuid'] = visitorId
    woopraIdentify(visitorId, membershipId)

    if (goodieBagRecordId) {
      const info = getInfoByGoodieBagRecordId(goodieBagRecordId, goodieBagRecordType, store, route)
      adobeTrackInfo = Object.assign({}, adobeTrackInfo, info['adobeTrackInfo'])
      woopraTrackInfo = Object.assign({}, woopraTrackInfo, info['woopraTrackInfo'])
    } else if (goodiebagID) {
      adobeTrackInfo['goodiebagID'] = goodiebagID
      woopraTrackInfo['goodiebag_id'] = goodiebagID
    }

    const countryCode = getCustomerCountryCode(store)
    woopraTrackInfo['country_code'] = countryCode

    sendAdobeEvent(store, 'onclick', adobeTrackInfo, null, route)
    sendWoopraAndGoogleEvent(store, 'onclick', woopraTrackInfo)
  }
}

const _sendManualAnalytics = function (route, locale, store, analyticsId, event, data) {
  let adobeTrackInfo = {}
  let woopraTrackInfo = {}
  let needTracking = true
  let goodieBagRecordId = null
  let goodieBagRecordType = null
  let goodiebagID = null

  console.log('@1001', data)
  if (!data) {
    data = {}
  }

  if (analyticsId === 'offer_detail_itinerary_swipe') {
    const { recordId, campaignId } = route.query
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    if (recordId) {
      goodieBagRecordId = recordId
      goodieBagRecordType = campaign.type
    }
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignType'] = campaign.type
    adobeTrackInfo['campaignName'] = campaign.name
    if (category) {
      adobeTrackInfo['campaignCategory'] = category
    }
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_type'] = campaign.type
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    if (category) {
      woopraTrackInfo['campaign_category'] = category
    }
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
  } else if (analyticsId === 'coupon_detail_itinerary_swipe') {
    const couponId = route.query.couponId
    const coupon = store.getters['coupons/couponDetail'](couponId)
    const campaignId = coupon.campaign.id
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignType'] = campaign.type
    adobeTrackInfo['campaignName'] = campaign.name
    adobeTrackInfo['campaignCategory'] = category
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_type'] = campaign.type
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    woopraTrackInfo['campaign_category'] = category
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
  } else if (analyticsId === 'coupon_use_coupon_success' || analyticsId === 'coupon_use_coupon_fail' ||
    analyticsId === 'coupon_detail_use_coupon_success' || analyticsId === 'coupon_detail_use_coupon_fail') {
    const couponId = route.query.couponId
    const coupon = store.getters['coupons/couponDetail'](couponId)
    const campaign = coupon.campaign || {}
    console.log('@63', coupon)
    const campaignCategorys = campaign.categories || []
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }
    if (analyticsId === 'coupon_detail_use_coupon_fail' || analyticsId === 'coupon_detail_use_coupon_success') {
      adobeTrackInfo['redemptionMethod'] = data.method
      woopraTrackInfo['redemption_method'] = data.method
    }

    adobeTrackInfo['analyticsId'] = 'esolution-event'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaign.id
    adobeTrackInfo['campaignType'] = campaign.type
    adobeTrackInfo['campaignName'] = campaign.name
    adobeTrackInfo['campaignCategory'] = category
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
    adobeTrackInfo['__couponId'] = couponId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_type'] = campaign.type
    woopraTrackInfo['campaign_id'] = campaign.id
    woopraTrackInfo['campaign_name'] = campaign.name
    woopraTrackInfo['campaign_category'] = category
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
    woopraTrackInfo['__coupon_id'] = couponId
    adobeTrackInfo['couponQty'] = data.coupon_qty
    woopraTrackInfo['coupon_qty'] = data.coupon_qty
  } else if (analyticsId === 'acquire_coupon_success') {
    const campaignId = route.query.campaignId
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }

    adobeTrackInfo['analyticsId'] = 'esolution-event'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignName'] = campaign.name
    adobeTrackInfo['campaignCategory'] = category
    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    woopraTrackInfo['campaign_category'] = category
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)

    adobeTrackInfo['couponQty'] = data.coupon_qty
    woopraTrackInfo['coupon_qty'] = data.coupon_qty
  } else if (analyticsId === 'redemption_successfully_ok' ||
    analyticsId === 'redemption_successfully_checkmywallet' ||
    analyticsId === 'redemption_successfully_browseroffer'
  ) {
    const { campaignId } = route.query
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    const campaignCategorys = campaign.categories
    let category = ''
    if (campaignCategorys.length) {
      category = campaignCategorys[0].name
    }

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['campaignType'] = campaign.type
    adobeTrackInfo['campaignName'] = campaign.name

    if (category) {
      adobeTrackInfo['campaignCategory'] = category
    }

    adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_type'] = campaign.type
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['campaign_name'] = campaign.name
    if (category) {
      woopraTrackInfo['campaign_category'] = category
    }
    woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
  } else if (analyticsId === 'acquire_goodie_bag_success') {
    const { record_id: recordId } = route.query
    goodieBagRecordId = recordId

    adobeTrackInfo['analyticsId'] = 'esolution-event'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (analyticsId === 'goodie_bag_myrewards_info_click' ||
    analyticsId === 'account_change_password_success' || analyticsId === 'account_change_password_fail' ||
    analyticsId === 'cookiepolicy_managemycookies') {
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (analyticsId === 'goodie_bag_campaignlist_info_click') {
    const { recordId, type } = route.query
    goodieBagRecordId = recordId
    goodieBagRecordType = type
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
  } else if (analyticsId === 'home_offer_redirection_campaign_click' ||
    analyticsId === 'home_offer_campaign_click' ||
    analyticsId === 'coupon_camera_fail') {
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    woopraTrackInfo['title'] = analyticsId

    const campaignId = data.id
    if (campaignId) {
      woopraTrackInfo['campaign_id'] = campaignId
      adobeTrackInfo['campaignId'] = campaignId

      const campaign = store.getters['campaigns/campaignDetail'](campaignId)

      if (campaign) {
        woopraTrackInfo['campaign_type'] = campaign.type
        woopraTrackInfo['campaign_name'] = campaign.name
        const campaignCategory = getCampaignCategory(campaign)
        woopraTrackInfo['campaign_category'] = campaignCategory
        woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
        adobeTrackInfo['campaignCategory'] = campaignCategory
      }
    }

    const cameraErrorMsg = data.camera_errormsg

    if (cameraErrorMsg) {
      woopraTrackInfo['camera_errormsg'] = cameraErrorMsg
      adobeTrackInfo['cameraErrormsg'] = cameraErrorMsg
    }
  } else if (analyticsId === 'acquire_coupon_fail_detail') {
    const campaignId = route.query.campaignId
    const campaign = store.getters['campaigns/campaignDetail'](campaignId)
    if (campaign) {
      const campaignCategory = getCampaignCategory(campaign)

      adobeTrackInfo['attributeTagValue'] = JSON.stringify(campaign.attribute_tags)
      adobeTrackInfo['campaignType'] = campaign.type
      adobeTrackInfo['campaignName'] = campaign.name
      adobeTrackInfo['campaignCategory'] = campaignCategory

      woopraTrackInfo['attribute_tag_value'] = JSON.stringify(campaign.attribute_tags)
      woopraTrackInfo['campaign_category'] = campaignCategory
      woopraTrackInfo['campaign_name'] = campaign.name
      woopraTrackInfo['campaign_type'] = campaign.type
    }
    const detail = data.detail
    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId
    adobeTrackInfo['campaignID'] = campaignId
    adobeTrackInfo['detail'] = detail

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['campaign_id'] = campaignId
    woopraTrackInfo['detail'] = detail
  } else if (analyticsId === 'click_yes_in_language_detection_pop_up' || analyticsId === 'click_no_in_language_detection_pop_up' ||
    analyticsId === 'language_detection_pop_up') {
    const { goodie_bag_id } = route.query
    goodiebagID = goodie_bag_id

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId

    adobeTrackInfo['goodiebagID'] = goodiebagID
    woopraTrackInfo['goodiebag_id'] = goodiebagID
  } else if (analyticsId === 'click_continue_in_goodiebag_restriction_pop_up' || analyticsId === 'click_exit_in_goodiebag_restriction_pop_up' ||
    analyticsId === 'click_OK_in_goodiebag(fortunebag)_out_of_stock_pop_up' || analyticsId === 'click_done_on_goodiebag(fortunebag)_success_pop_up') {
    const { goodie_bag_id } = route.query
    goodiebagID = goodie_bag_id

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    if (analyticsId === 'click_continue_in_goodiebag_restriction_pop_up') {
      adobeTrackInfo['analyticsText'] = 'click_continue_in_goodie_bag_restriction_pop_up'
    } else if (analyticsId === 'click_continue_in_goodiebag_restriction_pop_up') {
      adobeTrackInfo['analyticsText'] = 'click_exit_in_goodie_bag_restriction_pop_up'
    } else if (analyticsId === 'click_OK_in_goodiebag(fortunebag)_out_of_stock_pop_up') {
      adobeTrackInfo['analyticsText'] = 'goodie bag (fortune bag) out of stock pop up'
    } else if (analyticsId === 'click_done_on_goodiebag(fortunebag)_success_pop_up') {
      adobeTrackInfo['analyticsText'] = 'click_done_on_acquire_goodie_fortune_bag_success'
    }

    woopraTrackInfo['title'] = analyticsId

    adobeTrackInfo['goodiebagID'] = goodiebagID
    woopraTrackInfo['goodiebag_id'] = goodiebagID
  } else if (analyticsId === 'click_yes_in_use_coupon_confirmation_pop_up' || analyticsId === 'click_no_in_use_coupon_confirmation_pop_up' ||
    analyticsId === 'click_dismiss_button_in_use_coupon_confirmation_pop_up') {
    const { couponId } = route.query

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    if (analyticsId === 'click_yes_in_use_coupon_confirmation_pop_up') {
      woopraTrackInfo['title'] = 'click_yes_in_use coupon confirmation pop up'
    } else if (analyticsId === 'click_no_in_use_coupon_confirmation_pop_up') {
      woopraTrackInfo['title'] = 'click_no_in_use coupon confirmation pop up'
    } else if (analyticsId === 'click_dismiss_button_in_use_coupon_confirmation_pop_up') {
      woopraTrackInfo['title'] = 'click_no_in_use coupon confirmation pop up'
    }

    adobeTrackInfo['__couponId'] = couponId
    woopraTrackInfo['__coupon_id'] = couponId
  } else if (analyticsId === 'click_enlarge_button_to_barcode_images_in_coupon_details') {
    const { couponId } = route.query

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId

    adobeTrackInfo['__couponId'] = couponId
    woopraTrackInfo['__coupon_id'] = couponId
  } else if (analyticsId === 'generic_offer_campaign_submit_search' || analyticsId === 'goodie_bag_campaign_submit_search') {
    if (analyticsId === 'goodie_bag_campaign_submit_search') {
      const { recordId, type } = route.query
      goodieBagRecordId = recordId
      goodieBagRecordType = type
    }

    adobeTrackInfo['analyticsId'] = 'esolution-button'
    adobeTrackInfo['analyticsText'] = analyticsId

    woopraTrackInfo['title'] = analyticsId
    woopraTrackInfo['search_term'] = data['search_term']
  } else {
    needTracking = false
  }
  if (needTracking) {
    const lang = locale
    // const userAgent = navigator.userAgent
    woopraTrackInfo['language'] = lang
    // woopraTrackInfo['userAgent'] = userAgent

    const trackingChannel = getTrackingChannel()
    adobeTrackInfo['channel'] = trackingChannel
    woopraTrackInfo['source_channel'] = trackingChannel

    if (goodieBagRecordId) {
      const info = getInfoByGoodieBagRecordId(goodieBagRecordId, goodieBagRecordType, store, route)
      adobeTrackInfo = Object.assign({}, adobeTrackInfo, info['adobeTrackInfo'])
      woopraTrackInfo = Object.assign({}, woopraTrackInfo, info['woopraTrackInfo'])
    }

    const countryCode = getCustomerCountryCode(store)
    woopraTrackInfo['country_code'] = countryCode

    sendAdobeEvent(store, event, adobeTrackInfo, null, route)
    sendWoopraAndGoogleEvent(store, event, woopraTrackInfo)
  }
}

const _sendAdobePageViewAnalytics = function (route, locale, store, pageName, extra = {}) {
  const lang = locale
  const profile = store.getters['auth/userProfile']
  const membershipId = profile?.membership_id || profile?.membershipId || ''
  const countryCode = profile?.mobile_phone_number_country_code || ''

  const adobeTrackInfo = {
    event: 'pageview',
    page: {
      name: pageName,
      language: lang
    },
    member: { dhk_id: membershipId, sim_country_code: countryCode }
  }

  console.log('@event adobe pageName', pageName)
  console.log('@event adobe', adobeTrackInfo)
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push(adobeTrackInfo)
  } else {
    setTimeout(() => {
      window.adobeDataLayer.push(adobeTrackInfo)
    }, 1000)
  }
}

const getGoodiebagRewardType = function (goodieBagDetail) {
  let rewardType = goodieBagDetail.reward_type || ''
  if (rewardType === 'BOTH') {
    rewardType = 'cash, offer'
  } else if (rewardType === 'GIFT') {
    rewardType = 'offer'
  } else if (rewardType === 'CASH_VOUCHER') {
    rewardType = 'cash'
  } else if (rewardType === 'FORTUNE_BAG') {
    rewardType = 'Goodie bag offer (Direct assign)'
  }
  return rewardType
}

const _sendAdobeManualAnalytics = function (route, locale, store, eventName, extra = {}) {
  const lang = locale
  const profile = store.getters['auth/userProfile']
  const membershipId = profile?.membership_id || profile?.membershipId || ''
  const countryCode = profile?.mobile_phone_number_country_code || ''

  const { goodie_bag_id: goodiebagId, recordId, campaignId, couponId, storeId, errorMessage, useSpecialStore } = extra

  let adobeTrackInfo = {
    event: eventName,
    page: {
      name: eventName,
      language: lang
    },
    member: { dhk_id: membershipId, sim_country_code: countryCode }
  }

  if (eventName.includes('goodie|') && (eventName.includes('acquire') || eventName === 'goodie|landing page')) {
    adobeTrackInfo['goodiebag_id'] = `${goodiebagId || ''}`
    const goodieBagDetail = store.getters['goodiebags/goodieBagDetail'](goodiebagId)
    if (goodieBagDetail) {
      const rewardType = getGoodiebagRewardType(goodieBagDetail)
      const goodiebagName = goodieBagDetail.name || ''
      adobeTrackInfo['offer_type'] = rewardType
      adobeTrackInfo['goodiebag_name'] = goodiebagName
    }
    if (errorMessage) {
      adobeTrackInfo['error'] = {
        message: errorMessage
      }
    }
  } else if (eventName.includes('goodie|')) {
    const goodieBagRecord = store.getters['goodiebagparticipationrecords/recordDetail'](recordId) || {}
    const goodieBagDetail = goodieBagRecord.goodie_bag || {}
    const rewardType = getGoodiebagRewardType(goodieBagDetail)

    adobeTrackInfo = {
      ...adobeTrackInfo,
      ...extra,
      'goodiebag_id': `${goodieBagDetail.id || ''}`,
      'offer_type': rewardType,
      'goodiebag_name': goodieBagDetail.name || ''
    }
    delete adobeTrackInfo['recordId']
  } else if (eventName.includes('coupon|')) {
    const coupon = store.getters['coupons/couponDetail'](couponId) || {}
    const template = coupon.template || {}
    const brand = template.brand || {}
    const stores = template.applicable_stores || []
    const campaign = coupon.campaign || {}
    const goodiebag = coupon.goodie_bag || {}

    const category = (campaign.categories || []).map((item) => `C:${item.id}`).join('+')
    const subCategory = (campaign.attribute_tags || []).map((item) => `T:${item.id}`).join('+')

    let storeData = {}
    if (stores.length && storeId) {
      storeData = stores.filter((item) => item.id === storeId)[0]
    }
    if (useSpecialStore) {
      storeData = extra.storeData || {}
    }

    adobeTrackInfo = {
      ...adobeTrackInfo,
      'offer_type': campaign.type || '',
      'offer_category': category,
      'offer_sub_category': subCategory,
      'coupon_set_id': `${template.id || ''}`,
      'coupon_name': template.name || '',
      'coupon_id': `${couponId}`,
      'campaign_name': campaign.name || '',
      'campaign_id': `${campaign.id}`,
      'brand_id': `${brand.id || ''}`,
      'brand_name': brand.name || '',
      'store_name': storeId || useSpecialStore ? storeData?.name || '' : stores.map((item) => item.name).join('|'),
      'store_id': storeId || useSpecialStore ? `${storeData?.id || ''}` : stores.map((item) => item.id).join('|'),
      'goodiebag_name': `${goodiebag.name || ''}`,
      'goodiebag_id': `${goodiebag.id || ''}`
    }
    if (errorMessage) {
      adobeTrackInfo['error'] = {
        message: errorMessage
      }
    }
  } else if (eventName.includes('campaign|')) {
    const campaign = store.getters['campaigns/campaignDetail'](campaignId) || {}
    const brand = campaign.brand || {}
    const stores = campaign.stores || []
    const goodiebag = campaign.goodie_bag_data || {}

    const category = (campaign.categories || []).map((item) => `C:${item.id}`).join('+')
    const subCategory = (campaign.attribute_tags || []).map((item) => `T:${item.id}`).join('+')

    let storeData = {}
    if (stores.length && storeId) {
      storeData = stores.filter((item) => item.id === storeId)[0]
    }

    adobeTrackInfo = {
      ...adobeTrackInfo,
      'offer_type': campaign.type,
      'offer_category': category,
      'offer_sub_category': subCategory,
      'limited_quota_offer': campaign.is_starred ? 'y' : 'n',
      'campaign_name': campaign.name || '',
      'campaign_id': `${campaign.id}`,
      'brand_id': `${brand.id || ''}`,
      'brand_name': brand.name || '',
      'store_name': storeId ? storeData?.name || '' : stores.map((item) => item.name).join('|'),
      'store_id': storeId ? `${storeData?.id || ''}` : stores.map((item) => item.id).join('|'),
      'goodiebag_name': `${goodiebag.name || ''}`,
      'goodiebag_id': `${goodiebag.id || ''}`
    }
    if (errorMessage) {
      adobeTrackInfo['error'] = {
        message: errorMessage
      }
    }
  } else if (eventName.startsWith('mywallet|keyword search')) {
    adobeTrackInfo.event = eventName.split(' - ')[0]
    adobeTrackInfo.page.name = eventName.split(' - ')[1]

    adobeTrackInfo = {
      ...adobeTrackInfo,
      ...extra
    }
  } else {
    adobeTrackInfo = {
      ...adobeTrackInfo,
      ...extra
    }
  }
  console.log('@event adobe eventName', eventName)
  console.log('@event adobe', adobeTrackInfo)
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push(adobeTrackInfo)
  } else {
    setTimeout(() => {
      window.adobeDataLayer.push(adobeTrackInfo)
    }, 1000)
  }
}

const sendPageViewAnalytics = function (route, locale, store, extra = {}) {
  try {
    _sendPageViewAnalytics(route, locale, store, extra)
  } catch (error) {
    console.log('error@sendPageViewAnalytics', error)
  }
}

const sendClickAnalytics = function (route, locale, store, event) {
  try {
    _sendClickAnalytics(route, locale, store, event)
  } catch (error) {
    console.log('error@sendClickAnalytics', error)
  }
}

const sendManualAnalytics = function (route, locale, store, analyticsId, event, data) {
  try {
    _sendManualAnalytics(route, locale, store, analyticsId, event, data)
  } catch (error) {
    console.log('error@sendManualAnalytics', error)
  }
}

const sendAdobePageViewAnalytics = function (route, locale, store, pageName, extra = {}) {
  try {
    _sendAdobePageViewAnalytics(route, locale, store, pageName, extra)
  } catch (error) {
    console.log('error@sendAdobePageViewAnalytics', error)
  }
}

const sendAdobeManualAnalytics = function (route, locale, store, eventName, extra = {}) {
  try {
    _sendAdobeManualAnalytics(route, locale, store, eventName, extra)
  } catch (error) {
    console.log('error@sendAdobeManualAnalytics', error)
  }
}

export { sendPageViewAnalytics, sendClickAnalytics, sendManualAnalytics, sendAdobePageViewAnalytics, sendAdobeManualAnalytics }

